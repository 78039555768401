import React from 'react'
// import styles from "./pages.module.css"
import Layout from '../components/Layout/Layout'

class EventsPage extends React.Component {


  render() {

    return(
      <Layout>
        <h1>Events</h1>
        <div>

        </div>
      </Layout>
    )
  }
}

export default EventsPage
